@font-face {
font-family: 'circular';
src: url(/_next/static/media/67bca93f5149d2e0-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'circular';
src: url(/_next/static/media/a09247950fdb1f51-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'circular';
src: url(/_next/static/media/c86a9632483ce51d-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'circular';
src: url(/_next/static/media/48bc09c4ec73ee91-s.p.woff) format('woff');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: 'circular Fallback';src: local("Arial");ascent-override: 98.02%;descent-override: 26.35%;line-gap-override: 0.00%;size-adjust: 101.72%
}.__className_ae8398 {font-family: 'circular', 'circular Fallback'
}.__variable_ae8398 {--font-circular: 'circular', 'circular Fallback'
}

